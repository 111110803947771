import React, { createContext, useState } from "react";
import awsmobile from "../aws-exports";
import * as queries from "../graphql/queries";
import * as mutation from "../graphql/mutations";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { useForm } from "react-hook-form";

export const FromContext = createContext();

const defaultValues = {
  fullName: "",
  disclaimerChecked: "",
  emailAddress: "",
  photoUrl: "",
  streetAddress: "",
  streetName: "",
  addressAdditionalInfo: "",
  shippingCity: "",
  shippingState: "",
  shippingZipCode: "",
  selfieFileName: "",
  q1_response: "",
  q2_response: "",
  q3_response: "",
  q4_response: "",
  q5_response: "",
  q6_response: "",
  q7_response: "",
  q8_response: "",
  q9_response: "",
  q10_response: "",
  q11_response: "",
  q12_response: "",
  q13_response: "",
  userDataId: "",
  createdAt: "",
};

const FromDataProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [done, setDone] = useState(false);
  const [result, setResult] = useState(null);
  const { register, handleSubmit, formState, getValues, watch, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: undefined,
  });
  register('photoUrl')

  const handleProductResults = (id) => {
    try {
      axios({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        method: "post",
        headers: {
          "x-api-key": awsmobile.aws_appsync_apiKey,
        },
        data: {
          query: queries.getAkkioUserData,
          variables: {
            userDataId: id,
          },
        },
      })
        .then((res) => {
          setDone(true);
          // set last field to userDataId when testing; results in prod
          setResult(res.data.data.getAkkioUserData);
        })
        .catch((err) => {
          console.log("Log data fetch error 1:", err);
        });
    } catch (err) {
      console.log("error Log data fetch:", err);
    }
  };

  const handleGetPreSignedUrl = async (contentType) => {
    return axios({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": awsmobile.aws_appsync_apiKey,
      },
      data: {
        query: queries.getPreSignedS3Url,
        variables: {
          contentType,
        },
      },
    })
    .then((res) => {
      setDone(true);
      return res.data.data.getPreSignedS3Url;
    })
    .catch((err) => {
      console.log("Log data fetch error 1:", err);
    });
  };

  const handleAddUserData = (data) => {
    try {
      axios({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        method: "post",
        headers: {
          "x-api-key": awsmobile.aws_appsync_apiKey,
        },
        data: {
          query: mutation.createAkkioUserData,
          variables: {
            input: data,
          },
        },
      })
        .then((res) => {
          console.log("Added user data successfully!");
        })
        .catch((err) => {
          console.log("Add user data failed. reason:", err);
        });
    } catch (err) {
      console.log("error Log data insert:", err);
    }
  };

  const onSubmit = (data) => {
    setStep(step + 1);
    if (step === 17) {
      const id = uuidv4();
      localStorage.setItem("userId", id);
      data = { ...data, userDataId: id };
      handleAddUserData(data);

      setTimeout(function () {
        handleProductResults(id);
      }, 4000);
    }
  };

  const previousPage = (data) => {
    setStep(step - 1);
  };
  return (
    <FromContext.Provider
      value={{
        register,
        handleSubmit,
        getValues,
        errors: formState.errors,
        watch,
        onSubmit,
        handleGetPreSignedUrl,
        previousPage,
        step,
        done,
        result,
        setValue,
      }}
    >
      {children}
    </FromContext.Provider>
  );
};

export default FromDataProvider;
