/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPreSignedS3Url = /* GraphQL */ `
  query GetPreSignedS3Url($contentType: String) {
    getPreSignedS3Url(contentType: $contentType) {
      url
      key
    }
  }
`;
export const getAkkioUserData = /* GraphQL */ `
  query GetAkkioUserData($userDataId: ID!) {
    getAkkioUserData(userDataId: $userDataId) {
      userDataId
      userDataFlag
      logUserFlag
      fullName
      emailAddress
      streetAddress
      streetName
      addressAdditionalInfo
      shippingCity
      shippingState
      shippingZipCode
      selfieFileName
      photoUrl
      disclaimerChecked
      q1_response
      q2_response
      q3_response
      q4_response
      q5_response
      q6_response
      q7_response
      q8_response
      q9_response
      q10_response
      q11_response
      q12_response
      q13_response
      brand
      lighter_darker_flag
      multi_undertone_flag
      results
      skintone
      undertones
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listAkkioUserData = /* GraphQL */ `
  query ListAkkioUserData(
    $userDataId: ID
    $filter: ModelAkkioUserDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAkkioUserData(
      userDataId: $userDataId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userDataId
        userDataFlag
        logUserFlag
        fullName
        emailAddress
        streetAddress
        streetName
        addressAdditionalInfo
        shippingCity
        shippingState
        shippingZipCode
        selfieFileName
        photoUrl
        disclaimerChecked
        q1_response
        q2_response
        q3_response
        q4_response
        q5_response
        q6_response
        q7_response
        q8_response
        q9_response
        q10_response
        q11_response
        q12_response
        q13_response
        brand
        lighter_darker_flag
        multi_undertone_flag
        results
        skintone
        undertones
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
