import React, { useContext } from 'react';
import { FromContext } from '../../function';

const Page3 = () => {
		const { register, errors, getValues } = useContext(FromContext);
    return (
        <div>
	        <div className="vertical-center">


				<h1>Hey there, <span id="userNamePrinted">{getValues("fullName").split(" ", 1)}</span>!</h1>

	        	<center><p className="larger-font"> Thanks for your interest in Akkio! We will ask you for a selfie and then a set of questions. </p></center>

	        	<center><p className="larger-font">Once we match you, we’ll ship you 2-3 samples for free! Since we are in beta testing, it’s really important that you follow up with your feedback on which sample is the right match for you.</p></center>

	        	<center><p className="larger-font">First, what's your email and shipping address so we know where to send your free samples?</p></center>

				&nbsp;
				{
					errors.emailAddress && <div className="popup">
	        			<span className="popuptext show" id="namePopup">Please enter a valid email</span>
	        		</div>
				}

	        	<div className="get-started-div">
					<input
						type="text"
						id="emailAddress"
						name="emailAddress"
						className="get-started-div-input"
						placeholder="Email"
						{...register('emailAddress', {
							required: true,
							pattern: {value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'Please enter a valid email',
							}})
						}
						
					/>
	        	</div>
				<br />
				{
					errors.streetAddress && <div className="popup">
	        			<span className="popuptext show" id="namePopup">Please enter your address</span>
	        		</div>
				}

	        	<div className="get-started-div">
					<input
						type="text"
						id="streetName"
						className="address-div-input"
						placeholder="Street Name"
						name="streetName"
						{...register('streetName', { required: false })}
					/>
					<br />
					<input
						type="text"
						id="addressAdditionalInfo"
						className="address-div-input"
						placeholder="Apt# (Optional)"
						name="addressAdditionalInfo"
						{...register('addressAdditionalInfo', { required: false })}
					/>
					<br />
					<input
						type="text"
						id="shippingCity"
						className="address-div-input"
						placeholder="City"
						name="shippingCity"
						{...register('shippingCity', { required: false })}
					/>
					<br />
					<input
						type="text"
						id="shippingState"
						className="address-div-input"
						placeholder="State"
						name="shippingState"
						{...register('shippingState', { required: false })}
					/>
					<br />
					<input
						type="number"
						id="shippingZipCode"
						className="address-div-input"
						placeholder="Zip Code"
						name="shippingZipCode"
						{...register('shippingZipCode', { required: false })}
					/>
	        	</div>

	        	&nbsp;
	        	<button type='submit' id="to_selfie_button"  className="button-style">Let's get started &#x27F6;</button>
	        </div>
        </div>
    );
};

export default Page3;