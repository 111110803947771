import axios from 'axios';
import React, { useContext } from 'react';
import { FromContext } from '../../function';
const S3_BASE_URL = 'https://akkio-user-photos-beta.s3.us-east-1.amazonaws.com/';

const Page4 = () => {
	const { register, errors, watch, handleGetPreSignedUrl, setValue } = useContext(FromContext);
	const onFileUpload = (e) => {
		const image = watch('selfieFileName')
		handleGetPreSignedUrl(image[0].type)
			.then(res => uploadToS3(res))
	}

	const uploadToS3 = (res) => {
    axios
      .put(res.url, watch("selfieFileName")[0], {
        headers: {
          "Content-Type": watch("selfieFileName")[0].type,
        },
      })
      .then(() => {
        setValue('photoUrl', `${S3_BASE_URL}${res.key}`)
      })
      .catch((err) => {
        console.error(err);
      });
	}
    return (
      <div>
        <div className="vertical-center">
          <center>
            <p className="larger-font">
              Let's upload a head on selfie (including your face and neck). This photo is strictly being used for color matching and not as your profile picture in our system. For
              higher accuracy, we recommend you take this photo with no or
              minimal makeup and avoid shadows across your face. Don't worry, your photos are not attached to your name and other information you share here and is completely safe with us. 
            </p>
          </center>
          <br />
          &nbsp;
          {errors.selfieFileName && (
            <div className="popup">
              <span className="popuptext show" id="namePopup">
                Please upload a photo
              </span>
            </div>
          )}
          <div className="inputfile-box">
            <input
              type="file"
              id="file"
              name="userSelfie"
              accept="image/*"
              className="inputfile"
              {...register("selfieFileName", {
                required: true,
                onChange: (e) => onFileUpload(e),
              })}
            />
            <label
              style={{
                color: "#000",
                fontFamily: "Montserrat,sans-serif",
                fontSize: "calc(.5em + .7vw)",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              htmlFor="file"
            >
              <span id="file-name" className="file-box">
                {watch("selfieFileName")[0]?.name}
              </span>
              <span className="file-button">
                <i className="fa fa-upload" aria-hidden="true"></i>
                Select File
              </span>
            </label>
          </div>
          <br />
          <br />
          <p>
            <button type="submit" className="button-style">
              Questionnaire &#x27F6;
            </button>
          </p>
        </div>
      </div>
    );
};

export default Page4;