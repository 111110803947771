/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAkkioUserData = /* GraphQL */ `
  mutation CreateAkkioUserData(
    $input: CreateAkkioUserDataInput!
    $condition: ModelAkkioUserDataConditionInput
  ) {
    createAkkioUserData(input: $input, condition: $condition) {
      userDataId
      userDataFlag
      logUserFlag
      fullName
      emailAddress
      streetAddress
      streetName
      addressAdditionalInfo
      shippingCity
      shippingState
      shippingZipCode
      selfieFileName
      photoUrl
      disclaimerChecked
      q1_response
      q2_response
      q3_response
      q4_response
      q5_response
      q6_response
      q7_response
      q8_response
      q9_response
      q10_response
      q11_response
      q12_response
      q13_response
      brand
      lighter_darker_flag
      multi_undertone_flag
      results
      skintone
      undertones
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateAkkioUserData = /* GraphQL */ `
  mutation UpdateAkkioUserData(
    $input: UpdateAkkioUserDataInput!
    $condition: ModelAkkioUserDataConditionInput
  ) {
    updateAkkioUserData(input: $input, condition: $condition) {
      userDataId
      userDataFlag
      logUserFlag
      fullName
      emailAddress
      streetAddress
      streetName
      addressAdditionalInfo
      shippingCity
      shippingState
      shippingZipCode
      selfieFileName
      photoUrl
      disclaimerChecked
      q1_response
      q2_response
      q3_response
      q4_response
      q5_response
      q6_response
      q7_response
      q8_response
      q9_response
      q10_response
      q11_response
      q12_response
      q13_response
      brand
      lighter_darker_flag
      multi_undertone_flag
      results
      skintone
      undertones
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteAkkioUserData = /* GraphQL */ `
  mutation DeleteAkkioUserData(
    $input: DeleteAkkioUserDataInput!
    $condition: ModelAkkioUserDataConditionInput
  ) {
    deleteAkkioUserData(input: $input, condition: $condition) {
      userDataId
      userDataFlag
      logUserFlag
      fullName
      emailAddress
      streetAddress
      streetName
      addressAdditionalInfo
      shippingCity
      shippingState
      shippingZipCode
      selfieFileName
      photoUrl
      disclaimerChecked
      q1_response
      q2_response
      q3_response
      q4_response
      q5_response
      q6_response
      q7_response
      q8_response
      q9_response
      q10_response
      q11_response
      q12_response
      q13_response
      brand
      lighter_darker_flag
      multi_undertone_flag
      results
      skintone
      undertones
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
