import React, { useContext, useState } from 'react';
// import FromDataProvider from '../../function/index';
import { FromContext } from '../../function';

const Page18 = ({ step,setStep}) => {
	const { done, result } = useContext(FromContext);
	// const [result, setResult] = useState(null); 
	// const response = FromDataProvider.handleProductResults(localStorage.getItem('userId'));
    return (
		<>{(done && result) ?
        <div>
            <div className="vertical-center" style={{width:'100%'}}>
	        	<center><p className="larger-font">Thanks for uploading your selfie and answering our questions!</p></center>

	        	<center><p className="larger-font">Your mystery matches are:</p></center>

	        	<center className="final-match-style"><p className="even-larger-font">{ result.results }</p></center>

	        	<center><p className="larger-font">Keep an eye out for some free samples and an email with the subject line: 'Akkio Product Matching - Next Steps!' Be in touch soon.</p></center>

	        	<center><p className="larger-font">~ Akkio Team</p></center>

	        	<center><p><a href="https://akkiotech.com" className="home-button"><i className="fa fa-home" aria-hidden="true"></i></a></p></center>
	        </div>
        </div> : <span><div className="vertical-center-spinner">
            <div className="loader-text">Hang Tight!</div>
            <div className="loader-text">Our algorithm is at work.</div>
            <div className="loader"><div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
        </div></span>} </>
    );
};

export default Page18;